@import './spacing.scss';
@import './variables.scss';

:root {
    /* Slide opacity variables */
    --slide-opacity: 1;
    --slide-active-opacity: 1;

    /* Task card dimensions & styles */
    --arrow-size: 45px;
    --task-card-width: 85%;
    --task-card-height: 332px;
    --task-card-border: 1px solid #eff0f4;
    --task-card-border-radius: 8px;
    --task-card-background: #fff;
    --nav-offset: calc(344px + 20px);
}

.swiper {
    padding: 20px 0;
    position: relative;

    .swiper-wrapper {
        .swiper-slide {
            opacity: var(--slide-opacity);
            width: var(--task-card-width);
            min-width: var(--task-card-width);
            max-width: var(--task-card-width);
            min-height: var(--task-card-height);
            border: var(--task-card-border);
            border-radius: var(--task-card-border-radius);
            background: var(--task-card-background);
            transition: opacity 0.3s ease-in-out;

            -webkit-user-select: none; // Prevent text selection on WebKit browsers
            user-select: none; // Prevent text selection
            pointer-events: none; // Disable interactions

            &.swiper-slide-active {
                opacity: var(--slide-active-opacity);
                box-shadow: 0px 8px 35px 0px #00000029;

                -webkit-user-select: auto;
                user-select: auto; // Enable text selection on active slide
                pointer-events: auto; // Enable interactions on active slide
            }

            .taskCard {
                width: var(--task-card-width);
                min-width: var(--task-card-width);
                max-width: var(--task-card-width);
                min-height: var(--task-card-height);
            }
        }
    }

    /* Custom Navigation Buttons */
    .custom-swiper-button-prev,
    .custom-swiper-button-next {
        height: var(--arrow-size);
        width: var(--arrow-size);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        color: $primary;
        background: $white;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
        }
    }

    .custom-swiper-button-prev {
        left: calc(50% - var(--nav-offset));
    }

    .custom-swiper-button-next {
        right: calc(50% - var(--nav-offset));
    }
}

/* Responsive Section */
/* Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) {} */

/* Medium devices (tablets, 600px and up) */
@media (min-width: 600px) {
    :root {
        --slide-opacity: 0.25;
        --task-card-width: 344px;
        --nav-offset: calc(344px / 2 + 60px); // Restore correct positioning for tablet view
    }
}

/* Large devices (desktops, 992px and up) */
/* @media (min-width: 900px) {} */

/* X-Large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) {} */