/* Colors */
$black: #000000;
$white: #ffffff;
$gray1: #EFF0F4;
$gray2: #F9FAFC;
$orange: #DF6431;
$gray3: #ABB5BE;
$lightGreen: #8BB770;
$gray4: #68717A;
$dosenNavyBlue: #262738; // Left side bar color
$blue1: #0071A9;
$blue2: #1a8bed;
$bg: #F9FAFC;
$primary: #152536;
$secondary: #68717A;
$golden: #FEB272;

/** Chat */
$chatBorder: #DEE2E6;
$chatToolbarColor: #F8F8F8;

/** Size */
$leftBar: 350px; //Dasktop size
$containerHeight: calc(100% - 84px);
$p: 20px;
$m: 20px;

/** Loop variables */
$font-sizes: 10 12 14 16 18 20 22 24 26 28 30 32 34 36 38 40;
$font-weights: 400 600 700;
$spacing-values: 0 5 8 10 15 16 20 25; // Don't use 5 bcuz, 5 will take bootstrap (1-5),

/** List of cursors (comprehensive set) */
$cursors: (
  auto, default, none, context-menu, help, pointer, progress, wait, cell, crosshair, text, vertical-text, 
  alias, copy, move, not-allowed, grab, grabbing, all-scroll, 
  col-resize, row-resize, n-resize, s-resize, e-resize, w-resize, ne-resize, nw-resize, se-resize, sw-resize, 
  ew-resize, ns-resize, nesw-resize, nwse-resize, zoom-in, zoom-out
);
