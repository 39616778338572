/** Variables */
@import './variables.scss';

/***********************************************/

.task-main-sec {
  display: flex;
  height: calc(100% - 84px);
}

.left-task-sec {
  background: #fff;
  padding: 20px;
  width: $leftBar;
  min-width: $leftBar;
  overflow-y: auto;
}

.right-task-sec {
  width: 100%;
  margin: 20px;
  overflow-y: auto;
}

.my-level-txt {
  font-family: Open Sans !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #000 !important;
}

.main-icon-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.level-name {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: 'Open Sans !important';
  color: #abb5be !important;
  margin-bottom: 5px;
}

.your-goal-txt {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: 'Open Sans' !important;
  color: #000 !important;
}

.levelText {
  font-size: 14px !important;
  font-family: 'Open Sans' !important;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-banner-sec {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.img-style {
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
}

.first-card-sec {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #eff0f4;
}

.right-sec-heading {
  font-family: Open Sans !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #152536 !important;
}

.right-content-text {
  font-family: Open Sans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  color: #6c757d !important;
  padding: 15px 0;
}

.select-button-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.industries-sec {
  border-radius: 16px;
  border: 1px solid #DEE2E6;
  height: 50px;
  padding: 15px;
  display: flex;
  align-items: center;
}

.aeronautical-btn {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  color: #68717A;
  background: #EFF0F4;
  border-radius: 29px;
  border: 1px solid #CED4DA;
  margin-right: 10px;
}

/* button section code start */

.button-main-sec {
  background: #6c757d;
  border-radius: 16px;
  height: 50px;
  width: 169px;
  margin-right: 15px;
}

.button-main-sec:hover {
  background-color: #5d6870 !important;
}

.button-text {
  font-family: Open Sans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 14px;
  color: #fff !important;
}

.white-checkmark {
  padding: 0;
  margin-right: 8px;
  width: 24.29px;
  height: 24px;
}

/* button section code end*/
/* second card section css */
.second-card-heading {
  font-weight: 600 !important;
  color: #152536 !important;
  font-size: 16px !important;
  font-family: 'Open Sans' !important;
  cursor: pointer;
  padding: 20px;
}

.overviewText {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: 'Open Sans !important';
  color: #000000 !important;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.timeText {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Open Sans !important';
  color: '#68717A !important';
  margin-top: 8px;
}

.resume-main-sec {
  display: flex;
  column-gap: 20px;
  overflow-x: auto;
  width: 0;
  padding: 20px;
  max-width: 100%;
  min-width: 100%;
}

.second-resume-sec {
  display: flex;
  column-gap: 20px;
}

.second-card-img {
  padding: 0;
  margin: 0;
  width: 118px;
  height: 79px;
  border-radius: 5px;
  object-fit: cover;
}

/* Third card sec css */
.person-img-heading {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.mentor-img {
  width: 24px;
  height: 24px;
}

.my-mentor-text {
  font-family: Open Sans !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: #777777 !important;
  margin-left: 5px !important;

}

.myMentorsMainDiv {
  display: flex;
  width: 100%;
  gap: 20px;
  overflow-x: auto;
}

.MyMentorProfileMainBox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.profile-img {
  padding: 0;
  margin: 0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
}

.MyMentorProfile {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  width: 250px;
}

.profileHeading {
  color: #152536 !important;
  font-size: 16px !important;
  font-family: "Open Sans !important";
  font-weight: 600 !important;
}

.officerHeading {
  color: #68717A !important;
  font-size: 14px !important;
  font-family: "Open Sans !important";
  font-weight: 400 !important;
}

.alumniButton {
  color: #C7A429;
  font-size: 12px;
  font-weight: 600;
  font-family: "Open Sans";
  border: 1px solid #C7A429;
  border-radius: 5px;
  padding: 4px 8px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numberButton {
  display: flex;
  align-items: center;
  column-gap: 5px;
  border: 1px solid #11895E;
  border-radius: 5px;
  width: 44px;
  height: 24px;
  justify-content: center;
}

.chat-icon-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatandcelendericon {
  display: flex;
  gap: 8px;
  align-items: center;
}

.chatandcelendericon img {
  cursor: pointer;
}

.MenteetasksFieldSize {
  width: 50%;
}

.AutocompleteMenteetasksFieldSize .MuiFormControl-root {
  min-width: 50%;
}

.AutocompleteMenteetasksFieldSize .MuiFormControl-root .MuiInputBase-root {
  min-height: 50px;
  border-radius: 16px;
}

.onlyShowTwoLine {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;

  // display: box !important;
  // line-clamp: 2 !important;
  // box-orient: vertical !important;
}