/** Profile Page */
.profilePageHead {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;

  .profilePageImage {
    border: 2px solid #fff;
    border-radius: 50%;

    .Avatar {
      height: 60px;
      width: 60px;
    }
  }

  .userDetails {
    display: flex;
    flex-direction: column;
    width: calc(100% - 71px);
    gap: 8px;

    .NameTagEditIcon {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .NameTag {
        display: flex;
        align-items: center;
        gap: 8px;
        width: calc(100% - 40px);

        h1 {
          font-size: 20px;
        }
      }
    }

    .countryTimezone {
      display: flex;
      justify-content: space-between;
      gap: 8px;
    }
  }
}

@media (min-width: 600px) {
  .profilePageHead {
    gap: 20px;
    align-items: center;
    width: 100%;

    .profilePageImage {
      .Avatar {
        height: 95px;
        width: 95px;
      }
    }

    .userDetails {
      flex-direction: column;
      width: calc(100% - 120px);
      gap: 8px;

      .NameTagEditIcon {
        justify-content: space-between;
        align-items: center;

        .NameTag {
          align-items: center;
          gap: 8px;
          width: calc(100% - 40px);

          h1 {
            font-size: 24px;
          }
        }
      }

      .countryTimezone {
        justify-content: space-between;
        gap: 8px;
      }
    }
  }
}