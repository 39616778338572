@use "sass:string"; // Must be at the top of the file
@use './variables.scss'; // Import variables

/** Generate cursor classes both `cursor-grab` and `cursor-[grab]` styles */
/** Generate cursor classes dynamically */
@each $cursor in $cursors {

    /** Standard class like "cursor-grab" */
    .cursor-#{string.unquote($cursor)} {
        cursor: $cursor !important;
    }

    /** Bracket notation like "cursor-[grab]" */
    .cursor-\[#{string.unquote($cursor)}\] {
        cursor: $cursor !important;
    }
}

/* Additional helper classes */
.clickable,
.pointer {
    @extend .cursor-pointer;
}

.clickableNone,
.default {
    @extend .cursor-default;
}

/* Overflow */
.overflow-hidden,
.overflow-none {
    overflow: hidden !important
}

.overflowX-hidden,
.overflowX-none {
    overflow-x: hidden !important
}

.overflowY-hidden,
.overflowY-none {
    overflow-y: hidden !important
}

.overflow {
    overflow: auto
}

.overflowX {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

.overflowY {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.pointerEvents-none {
    pointer-events: none !important;
}

.pointerEvents-all {
    pointer-events: all !important;
}

/** Image */
.objectFit-cover {
    object-fit: cover !important;
}

.br-8 {
    border-radius: 8px !important;
}

.br-0 {
    border-radius: 0px !important;
}

.border-dashed {
    border-style: dashed !important;
}

/** Size */
.size-15 {
    height: 15px !important;
    width: 15px !important;
}

.size-20 {
    height: 20px !important;
    width: 20px !important;
}

/** Width */
.w-100p {
    width: 100% !important;
}

.w-50p {
    width: 50% !important;
}

.h-35 {
    height: 35px !important;
}

.w-50px {
    width: 50px !important;
}

.w-85, .w-85px {
    width: 85px !important;
}

.max-w-246px {
    max-width: 246px !important;
}

.w-150px {
    width: 150px !important;
}

.w-150px {
    width: 150px !important;
}

.w-160px {
    width: 160px !important;
}

.maxWidth-min-content {
    max-width: min-content !important;
}

/** Height */
.h-100p {
    height: 100% !important;
}

.h-50p {
    height: 50% !important;
}

.h-50px {
    height: 50px !important;
}

.h-100px {
    height: 100px !important;
}

/** Border */
.b-none {
    border: none !important;
}

.b-0 {
    border: 0 !important;
}

.HW-20 {
    height: 20px !important;
    width: 20px !important;
}

/** Opacity */
// Mixin for generating opacity classes
@mixin opacity-class($value, $suffix: null) {
    .opacity-#{$suffix} {
        opacity: $value !important;
    }
}

// Generate opacity classes dynamically
@include opacity-class(0.10, "01");
@include opacity-class(0.20, "02");
@include opacity-class(0.25, "25");
@include opacity-class(0.30, "03");
@include opacity-class(0.40, "04");
@include opacity-class(0.50, "05");
@include opacity-class(0.50, "50"); // Alias for 50%
@include opacity-class(0.60, "06");
@include opacity-class(0.70, "07");
@include opacity-class(0.75, "75");
@include opacity-class(0.80, "08");
@include opacity-class(0.90, "09");
@include opacity-class(1, "1");
@include opacity-class(1, "100"); // Alias for 100%


// Common mixin for disabling interactions
@mixin disabled-box($opacity: null) {
    -webkit-user-select: none !important; // Prevent text selection on webkit browsers
    user-select: none !important; // Prevent text selection on other browsers
    pointer-events: none !important; // Disable all interactions

    @if $opacity !=null {
        opacity: $opacity !important; // Set opacity if provided
    }
}

// Base disabled box style
.disabled-box {
    @include disabled-box();
}

// Modifier classes for different opacity levels
.disabled-box-opacity-25 {
    @include disabled-box(0.25); // 25% opacity
}

.disabled-box-opacity-50 {
    @include disabled-box(0.50); // 50% opacity
}

.disabled-box-opacity-75 {
    @include disabled-box(0.75); // 75% opacity
}

/**
 * Dynamic multi-line truncation with ellipsis
 * Generates classes .lineClamp-2 to .lineClamp-10
 * Example usage: <p class="lineClamp-3">This text will be truncated at 3 lines.</p>
 */
@mixin lineClampBase {
    display: -webkit-box !important; //Enables line clamping
    -webkit-box-orient: vertical !important; //Ensures vertical text flow
    overflow: hidden !important; // Hides overflowing content
    text-overflow: ellipsis !important; // Shows "..." when text is truncated
}

@for $i from 2 through 10 {
    .lineClamp-#{$i} {
        @include lineClampBase; //Includes base clamping styles
        -webkit-line-clamp: $i !important; //Limits text to $i lines
        line-clamp: $i !important; //Standard property for compatibility
    }
}

/*  ============================================
    ✅ Custom Utility Classes
    ============================================ */
@layer utilities {
    .inline-fill {
        inline-size: -webkit-fill-available;
    }
}

/** Flex */
.flex-1 {
    flex: 1 !important;
}

/** Hover Define hover color utility classes for Btn */
$hover-colors: (
  black: $black,
  blue: $blue1,
  inherit: #262738
);

@each $name, $color in $hover-colors {
  .hover-#{$name}:hover {
    background-color: $color !important;
    color: #FFFFFF;
  }
}